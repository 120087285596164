import { useEffect, useState } from 'react';
import { LiveChatWidget, useWidgetIsReady } from '@livechat/widget-react';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

// -----------------------------------------------------------

import {
  placeholder_container,
  placeholder_fade_out,
  placeholder_hidden,
  placeholder_button
} from './styles.module.scss';

// -----------------------------------------------------------

const WidgetHiddenPaths = [
  '/',
  '/coming-soon',
  'blog',
  'home-services',
  'relocation',
  'account',
  'results'
];

// https://www.npmjs.com/package/@livechat/widget-react

const ChatWidget = () => {
  const router = useRouter();

  // Determines if the chat widget is loaded on the page at all.
  const [isWidgetUsed, setIsWidgetUsed] = useState(false);

  // Removes the placeholder after the fade animation has finished.
  const [placeholderHidden, setPlaceholderHidden] = useState(false);

  const isWidgetReady = useWidgetIsReady();

  const { isSibcyListing } = useSelector((state) => state.miscValues);

  useEffect(() => {
    const { pathname, query } = router;

    const isHiddenPath = WidgetHiddenPaths.some((hiddenPath) => {
      // If the path doesn't starts with '/', it's a base slug and only needs to be included in the pathname.
      if (hiddenPath.startsWith('/')) {
        return pathname === hiddenPath;
      } else {
        return pathname.includes(hiddenPath);
      }
    });

    // Special logic for listing detail pages.
    const isListingDetailPage = pathname.includes('listing');
    const isWidgetHiddenListing =
      isSibcyListing || Boolean(query?.agentid) || Boolean(query?.tourModal);

    if (isHiddenPath || (isListingDetailPage && isWidgetHiddenListing)) {
      setIsWidgetUsed(false);
    } else {
      setIsWidgetUsed(true);
    }
  }, [router, isSibcyListing]);

  if (isWidgetUsed) {
    return (
      <>
        <div
          className={classNames(placeholder_container, {
            [placeholder_fade_out]: isWidgetReady,
            [placeholder_hidden]: placeholderHidden
          })}
          onTransitionEnd={() => {
            setPlaceholderHidden(true);
          }}
        >
          <button className={placeholder_button}>
            <svg color="inherit" viewBox="0 0 32 32">
              <path
                fill="#939598"
                d="M12.63,26.46H8.83a6.61,6.61,0,0,1-6.65-6.07,89.05,89.05,0,0,1,0-11.2A6.5,6.5,0,0,1,8.23,3.25a121.62,121.62,0,0,1,15.51,0A6.51,6.51,0,0,1,29.8,9.19a77.53,77.53,0,0,1,0,11.2,6.61,6.61,0,0,1-6.66,6.07H19.48L12.63,31V26.46"
              ></path>
              <path
                fill="#e7e7e7"
                d="M19.57,21.68h3.67a2.08,2.08,0,0,0,2.11-1.81,89.86,89.86,0,0,0,0-10.38,1.9,1.9,0,0,0-1.84-1.74,113.15,113.15,0,0,0-15,0A1.9,1.9,0,0,0,6.71,9.49a74.92,74.92,0,0,0-.06,10.38,2,2,0,0,0,2.1,1.81h3.81V26.5Z"
              ></path>
            </svg>
          </button>
        </div>
        <LiveChatWidget
          chatBetweenGroups={false}
          visibility={isWidgetReady ? 'minimized' : 'hidden'}
          license="8045841"
          group="378"
        />
      </>
    );
  } else {
    return null;
  }
};
export default ChatWidget;
