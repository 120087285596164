import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { NextSeo } from 'next-seo';
import { useRouter } from 'next/router';
import { getSiteURL, truncateMarketingRemarks, processMetaTitle } from '@lib/utils';
import {
  generateWebsiteSchema,
  generateRealEstateSchema,
  generateAboutSchema,
  generateContactSchema
} from '@lib/generate-schemas';

// ---------------------------------------------------------

import { component as Footer } from './footer';
import { component as Header } from './header';

const DebugMediaQueries = dynamic(() => import('./debug-media-queries'), {
  loading: () => <></>
});

// ---------------------------------------------------------

import {
  theme_gradient,
  preview_alert,
  container_footer_at_bottom,
  main,
  preview_desktop_title,
  preview_mobile_title
} from './styles.module.scss';
import Button from '@components/button';

const themeOptions = {
  gradient: theme_gradient,
  none: null
};

// ---------------------------------------------------------

const Layout = ({
  canonicalUrl,
  children,
  meta,
  nofollow,
  theme,
  noCanonical,
  collection,
  preview,
  dynamicOgImage,
  homePath,
  specializedPageSchema,
  listingDetails
}) => {
  // -------------------------------------------------------

  const classes = classNames(main, {
    [themeOptions[theme]]: themeOptions[theme]
  });

  // -------------------------------------------------------

  const router = useRouter();

  const excludedQueryParams = ['agentid', 'modal'];

  const removeQueryParams = (url) => {
    const urlObj = new URL(url);
    const params = urlObj.searchParams;

    excludedQueryParams.forEach((param) => {
      params.delete(param);
    });
    return urlObj.toString();
  };

  const generatedCanonicalUrl =
    router.asPath === `/` ? getSiteURL() : removeQueryParams(getSiteURL() + router.asPath);

  let imageURL =
    meta?.image?.url ||
    'https://images.ctfassets.net/wzzep3ntdgx4/5Eb7vSONDVgHo19WjNWS0h/21d57ff840dde6ace67a8d41dc468fdb/sibcyclinerealtors.png';

  const defaultDescription =
    '#1 Local Real Estate Search site in Cincinnati, Dayton, NKY, & SE Indiana. Find a real estate agent. Get pre-approved for a loan. Shop insurance rates.';

  const defaultTitle = 'Homes for Sale, Mortgage, Insurance & More | Sibcy Cline REALTORS®';
  const defaultTitleNoSuffix = 'Sibcy Cline';

  const getMetaTitle = () => {
    if (collection !== 'Main') {
      return meta?.title || defaultTitleNoSuffix;
    }

    if (router.asPath.includes('/listing/')) {
      return processMetaTitle(meta?.title);
    } else {
      return (meta?.title && `${meta.title} | Sibcy Cline REALTORS®`) || defaultTitle;
    }
  };

  const nextMeta = {
    canonical: canonicalUrl || (!noCanonical && generatedCanonicalUrl) || null,
    description: meta?.description || defaultDescription,
    imageAlt: meta?.image?.alt || '',
    imageUrl: dynamicOgImage || imageURL,
    title: getMetaTitle(),
    url: canonicalUrl || getSiteURL() + router.asPath
  };

  // Define the schema generators for each specialized page
  const schemaGenerators = {
    websiteSchema: generateWebsiteSchema, // /
    realEstateSchema: generateRealEstateSchema, // /listing/[id]
    aboutSchema: generateAboutSchema, // /about
    contactSchema: generateContactSchema // /contact
  };

  let schema = null;
  if (specializedPageSchema) {
    const generateSchema = schemaGenerators[specializedPageSchema];

    if (generateSchema) {
      const outputSchema = listingDetails
        ? generateSchema(listingDetails)
        : generateSchema(nextMeta);

      schema = outputSchema;
    }
  }
  // -------------------------------------------------------

  return (
    <>
      <NextSeo
        canonical={noCanonical ? null : nextMeta.canonical}
        nofollow={nofollow}
        openGraph={{
          images: [
            {
              alt: nextMeta.imageAlt,
              height: 630,
              url: nextMeta.imageUrl,
              width: 1200
            }
          ],
          siteName: 'Sibcy Cline REALTORS®',
          type: 'website',
          url: nextMeta.url
        }}
        title={nextMeta.title}
        description={truncateMarketingRemarks(nextMeta.description)}
        twitter={{
          cardType: 'summary_large_image',
          site: '@sibcycline'
        }}
        additionalLinkTags={[
          {
            href: '/feeds/rss.xml',
            rel: 'alternate',
            title: 'SibcyCline.com Blog RSS Feed',
            type: 'application/rss+xml'
          }
        ]}
      />

      <div className={container_footer_at_bottom}>
        <Header collection={collection} homePath={homePath} />
        <main className={classes}>{children}</main>
        <Footer collection={collection} homePath={homePath} />
      </div>

      {preview && (
        <div className={preview_alert}>
          <h3>
            <span className={preview_desktop_title}>Viewing Preview</span>
            <span className={preview_mobile_title}>Preview</span>
          </h3>
          <Button label="Exit preview" url="/api/exit-preview" />
        </div>
      )}

      <DebugMediaQueries />

      {schema && (
        <Head>
          <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: schema }} />
        </Head>
      )}
    </>
  );
};

Layout.propTypes = {
  canonicalUrl: PropTypes.string,

  children: PropTypes.node.isRequired,

  /**
   * Collection name of the core services, to indicate the header style
   */
  collection: PropTypes.oneOf([
    'Main',
    'Mortgage',
    'Insurance',
    'Relocation',
    'Title',
    'HomeServices'
  ]),

  meta: PropTypes.shape({
    description: PropTypes.string,
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    title: PropTypes.string
  }),

  /**
   * Specifies if we don't want an auto generated canonical url added
   */
  noCanonical: PropTypes.bool,

  /**
   * Specifies if the page should contain a nofollow meta tag.
   */
  nofollow: PropTypes.bool,

  /**
   * Specifies the listing details for the LDP real estate schema
   */
  listingDetails: PropTypes.object,

  /**
   * Specifies whether the page is shown in preview mode
   */
  preview: PropTypes.bool,

  /**
   * Specifies the theme.
   */
  theme: PropTypes.oneOf(Object.keys(themeOptions)),

  /**
   * Specifies the OG image.
   */
  dynamicOgImage: PropTypes.string,
  homePath: PropTypes.string,
  /**
   * Specifies the function used to generate the schema for the page.
   */
  specializedPageSchema: PropTypes.oneOf([
    'websiteSchema',
    'realEstateSchema',
    'aboutSchema',
    'contactSchema'
  ])
};

Layout.defaultProps = {
  collection: 'Main',
  noCanonical: false,
  theme: 'none'
};

export default Layout;
