const icons = [
  {
    name: [
      'arrow',
      'bars',
      'check',
      'close',
      'email',
      'notes',
      'notification',
      'phone',
      'search',
      'share',
      'sort-by',
      'trash',
      'text',
      'website'
    ],
    type: 'actions'
  },
  {
    name: [
      'default-down',
      'default-left',
      'default-right',
      'default-up',
      'large-left',
      'large-right',
      'medium-left',
      'medium-right',
      'small-down',
      'small-left',
      'small-right',
      'small-up'
    ],
    type: 'arrow'
  },
  {
    name: [
      'coming-soon',
      'floorplan',
      'indiana',
      'kentucky',
      'like',
      'like-outline',
      'location',
      'neighborhood',
      'new-construction',
      'new-listing',
      'notification',
      'ohio',
      'open-house',
      'photos',
      'price-change',
      'price-range',
      'school-district',
      'streetview',
      'search',
      'video'
    ],
    type: 'filters'
  },
  { name: ['facebook', 'instagram', 'linkedin', 'x', 'pinterest'], type: 'social' },
  {
    name: [
      'recenter',
      'house',
      'default-marker',
      'new-price-marker',
      'office-marker',
      'open-marker',
      'pending-marker',
      'sold-marker'
    ],
    type: 'map'
  },
  { name: ['account', 'house', 'calendar', 'key', 'clock'], type: 'general' }
];

// export default icons;
module.exports = icons;
