import PropTypes from 'prop-types';
import classNames from 'classnames';

// ---------------------------------------------------------

import Icon from '@components/icon';
import Link from '@components/link';

// ---------------------------------------------------------

import {
  button,
  icon_container,
  theme_black,
  theme_gray_outline,
  theme_red_outline,
  theme_red,
  theme_text_only_button,
  theme_text_only_red_button,
  theme_text_only_white_button,
  theme_text_only_black_button,
  theme_text_only_white_button_with_caret_button,
  theme_with_icon,
  theme_red_text_only_with_caret_button,
  theme_text_only_with_caret_button,
  container
} from './styles.module.scss';

const themeOptions = {
  black: theme_black,
  'gray-outline': theme_gray_outline,
  red: theme_red,
  'red-outline': theme_red_outline,
  'red-text-only-with-caret': theme_red_text_only_with_caret_button,
  'text-only': theme_text_only_button,
  'text-only-white': theme_text_only_white_button,
  'text-only-black': theme_text_only_black_button,
  'text-only-white-with-caret': theme_text_only_white_button_with_caret_button,
  'text-only-red': theme_text_only_red_button,
  'text-only-with-caret': theme_text_only_with_caret_button,
  with_icon: theme_with_icon
};

// ---------------------------------------------------------

const Button = (props) => {
  let {
    className,
    disabled,
    icon,
    iconType,
    label,
    modalButton,
    onClick,
    theme,
    title,
    type,
    url
  } = props;

  // -------------------------------------------------------

  const anchorUrl = url?.match(/^#/);

  const handleScrollTo = () => {
    const element = document.getElementById(url.slice(1));
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth'
      });
    }
  };

  // -------------------------------------------------------

  const classes = classNames({
    [button]: theme !== 'text-only',
    [className]: className,
    [themeOptions[theme]]: themeOptions[theme]
  });

  // -------------------------------------------------------

  const showCarets =
    theme === 'text-only-white-with-caret' ||
    theme === 'text-only-with-caret' ||
    theme === 'red-text-only-with-caret';

  const buttonContents = (
    <div className={container}>
      {icon && (
        <span className={icon_container}>
          <Icon type={iconType ? iconType : `filters`} name={icon} />
        </span>
      )}
      {label}
      <div>{showCarets && <Icon type={'arrow'} name={'small-right'} />}</div>
    </div>
  );

  let buttonComponent = (
    // renders a element
    <Link className={classes} url={url} title={title}>
      {buttonContents}
    </Link>
  );

  if (anchorUrl) {
    buttonComponent = (
      // renders button element that scrolls to an anchor tag on the page
      <button className={classes} onClick={handleScrollTo} disabled={disabled}>
        {buttonContents}
      </button>
    );
  }

  if (!url && (onClick || type)) {
    buttonComponent = (
      // renders button element
      <button className={classes} onClick={onClick} type={type} disabled={disabled}>
        {buttonContents}
      </button>
    );
  }

  if (modalButton) {
    buttonComponent = <div className={classes}>{buttonContents}</div>;
  }

  return buttonComponent;
};

Button.propTypes = {
  /**
   * Specifies if the button is disabled
   */
  disabled: PropTypes.bool,

  /**
   * Specifies the icon name, optional
   */
  icon: PropTypes.string,

  /**
   * Specifies the icon type (with default of 'filters')
   */
  iconType: PropTypes.string,

  /**
   * Specifies the label
   */
  label: PropTypes.string,

  /**
   * Specifies if the button is being used in a modal,
   * this will generate a div that is styled like a button
   */
  modalButton: PropTypes.bool,

  /**
   * Specifies a click function and renders a `<button>` element.
   */
  onClick: PropTypes.func,

  /**
   * Specifies the theme.
   */
  theme: PropTypes.oneOf(Object.keys(themeOptions)),

  /**
   * Specifies the title attribute
   */
  title: PropTypes.string,

  /**
   * Specifies the type attribute and renders a `<button>` element.
   */
  type: PropTypes.oneOf(['submit', 'button']),

  /**
   * Specifies the href attribute.
   */
  url: PropTypes.string
};

Button.defaultProps = {
  theme: 'red',
  type: 'submit'
};

export default Button;
