import { fetch } from '@lib/utils';
import nodeFetch from 'node-fetch';
import apollo from '@lib/apolloClient';

/**
 * @param {Boolean} ssr
 * @returns
 */
export const restEndpoint = (ssr = false) => {
  let baseUrl;
  // SSR pages & unit tests cannot rely on NextJS proxy...
  if (
    process.env.NODE_ENV === 'test' ||
    (process.env.NEXT_PUBLIC_VERCEL_ENV === 'production' && ssr)
  ) {
    baseUrl = 'https://api.sibcycline.com/v2';
  } else if (ssr) {
    // SSR pages when not in production should hit the staging API
    baseUrl = 'https://api-staging.sibcycline.com/v2';
  } else {
    // Any client side routes get proxied through the /rest rewrite
    baseUrl = '/rest';
  }

  return baseUrl;
};

/**
 *
 * @param {*} path
 * @param {*} params
 * @returns
 */
export const rest = async (path, params, ssr = false) => {
  try {
    const url = `${restEndpoint(ssr)}${path}`;
    const data = await fetch({
      url: url,
      ...params
    });

    return data;
  } catch (error) {
    console.error(error);
  }
};

// Direct fetch method to circumvent Apollo Client
export const fetchGraphQL = async (query, variables) => {
  const GRAPHQL_ENDPOINT = 'https://api.sibcycline.com/v2/graphql';
  const response = await nodeFetch(GRAPHQL_ENDPOINT, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      query: query,
      variables: variables
    })
  });

  const jsonResponse = await response.json();
  if (!response.ok) {
    throw new Error(
      `GraphQL query failed: ${jsonResponse.errors?.map((e) => e.message).join(', ')}`
    );
  }
  return jsonResponse.data;
};

/**
 *
 * @param {*} query
 * @param {*} variables
 * @returns
 */
export const graphql = async (query, variables) => {
  const { loading, error, data } = await apollo.query({
    query,
    variables: variables,
    fetchPolicy: 'network-only'
  });
  if (loading) return 'Loading...';
  if (error) throw error;
  return data;
};
